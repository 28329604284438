import { Modal } from "tailwindcss-stimulus-components"

export default class OnboardingModal extends Modal {

  static targets = ["experienceLevel", "goal", "favoriteGenres", "reminderCadence"]

  connect() {
    super.connect()
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.onboarding_survey === 'true') {
      this.containerTarget.classList.remove('fadeOut')
      this.containerTarget.classList.remove('hidden')
      document.getElementById('onboarding-survey-trigger').click()
    }
  }

  validateInputs() {
    let inputsValid = true
    if (this.experienceLevelTarget.value === "unknown") {
      inputsValid = false
      this.experienceLevelTarget.classList.add("error")
      document.getElementById("onboarding-experience-level-input-error").classList.remove("hidden")
    }
    if (this.goalTarget.value === "") {
      inputsValid = false
      this.goalTarget.classList.add("error")
      document.getElementById("onboarding-goal-input-error").classList.remove("hidden")
    }
    return inputsValid
  }

  submitResponse() {
    fetch("/api/v1/onboarding_survey", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          onboarding_survey: {
            experience_level: this.experienceLevelTarget.value,
            goal: this.goalTarget.value,
            favorite_genres: this.favoriteGenresTargets.filter((target) => target.checked).map((target) => target.value),
            reminder_cadence: this.reminderCadenceTarget.value
          }
        }
      )
    })
    .then((response) => {
      if (response.ok) {
        this.close()
      } else {
        alert("oh boi")
      }
    })
    .catch((error) => alert("uh oh"))
  }

  removeError(event) {
    if (event.target.value !== "") {
      document.getElementById(event.params.elementId).classList.add("hidden")
      event.target.classList.remove("error")
    }
  }

  submit() {
    if (this.validateInputs()) {
      this.submitResponse()
    }
  }

  close() {
    this.containerTarget.classList.add('fadeOut')
    setTimeout(()=> super.close(), 300)
  }

}
