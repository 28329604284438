import Chart from 'stimulus-chartjs'

export default class extends Chart {
  static values = {
    yAxisLabel: String
  }

  changeToTimeOfDay(value) {
    var time = value % 12 == 0 ? 12 : value % 12
    var timeString = time + (value > 11 && value != 24 ? " PM" : " AM")
    return timeString
  }

  changeToDayOfWeek(value) {
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ][value]
  }

  formatLabel(context) {
    var numbers = context.raw
    let dayOfWeek = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"][numbers.x]
    let timeOfDay = (numbers.y % 12 == 0 ? 12 : numbers.y % 12) + (numbers.y > 11 && numbers.y != 24 ? " PM" : " AM")
    let mood = numbers.mood
    let strings
    if (context.label == "This past week") {
      strings = [
        `${context.label} on`,
        `${dayOfWeek} you wrote at ${timeOfDay}`
      ]
    } else if (context.label == "This past month") {
      strings = [
        `Over the past month on`,
        `${dayOfWeek}s at ${timeOfDay}`,
        `You wrote ${numbers.r / 7} ${ numbers.r == 5 ? 'time' : 'times'}`,
      ]
    } else if (context.dataset?.label == "Reminder Times") {
      strings = [
        `When we try to remind you to write`
      ]
    }
    return strings
  }

  connect() {
    super.connect()

    // The chart.js instance
    this.chart

    // Options from the data attribute.
    this.options

    // Default options for every charts.
    this.defaultOptions
  }

  get defaultOptions() {
    return {
      plugins: {
        legend: {
          position: 'top',
          labels: {
            filter: function(item, chart) {
                // Logic to remove a particular legend item goes here
                return !item.text.includes('Reminder Times');
            }
          }
        },
        title: {
          display: true,
          text: 'Your writing habits'
        },
        tooltip: {
          callbacks: {
            label: this.formatLabel
          }
        }
      },
      scales: {
        y: {
          min: 0,
          max: 24,
          ticks: {
            callback: this.changeToTimeOfDay,
            stepSize: 2,
          }
        },
        x: {
          min: -1,
          max: 7,
          ticks: {
            callback: this.changeToDayOfWeek
          }
        }
      }
    }
  }
}
