// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["emailInput"]

  connect() {
    if (window.sessionStorage.getItem("email")) {
      this.emailInputTarget.value = window.sessionStorage.getItem("email")
    }
  }
}
