import React from 'react';

import {
  ResultContainer,
  ResultImage,
  SongContainer,
  TitleText,
  ArtistText,
} from './styles/TrackSearchResults.styles';

const TrackSearchResult = ({ track, chooseTrack }) => {
  function handlePlay() {
    chooseTrack(track);
  }

  const albumImage = track.album.images.reduce((smallest, image) => {
    if (image.height < smallest.height) return image;
    return smallest;
  }, track.album.images[0])

  return (
    <ResultContainer onClick={handlePlay}>
      <ResultImage src={albumImage.url} />
      <SongContainer>
        <TitleText>{track.name}</TitleText>
        <ArtistText>{track.artists.map((artist) => (artist.name)).join(", ")}</ArtistText>
      </SongContainer>
    </ResultContainer>
  );
};

export default TrackSearchResult;
