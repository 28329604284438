import React, { useState, useEffect } from 'react';
import Player from "./MusicPlayer/Player"
import TrackSearchResult from './MusicPlayer/TrackSearchResults';
import SpotifyWebApi from 'spotify-web-api-node';

export default function MusicPlayerContainer({accessToken, clientId, clientSecret}) {
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [trackUri, setTrackUri] = useState(null)

  const spotifyApi = new SpotifyWebApi({
    clientId: clientId,
    clientSecret: clientSecret,
    redirectUri: 'http://www.example.com/callback'
  });

  spotifyApi.setAccessToken(accessToken)

  const searchForSongs = (e) => {
    e.preventDefault()
    document.getElementById("search-results-container").scrollTop = 0
    spotifyApi.searchTracks(search).then(function(data) {
      setSearchResults(data.body.tracks.items)
    })
  }

  return (
    <>
      <div className="form-group">
        <label>Play Some Music</label>
        <div className="flex flex-wrap items-center gap-2">
          <input className="form-control flex-1" value={search} onChange={(e) => { setSearch(e.target.value)}}></input>
          <button className="btn btn-primary" onClick={searchForSongs}>Search</button>
        </div>
        <div className="mb-1" style={{maxHeight: "150px", overflowY: "scroll"}} id="search-results-container">
          {
            searchResults.map((result) => (
              <TrackSearchResult track={result} key={result.uri} chooseTrack={() => setTrackUri(result.uri)} />
            ))
          }
        </div>
        {trackUri && <Player accessToken={accessToken} trackUri={trackUri} autoPlay={true} />}
      </div>
    </>
  )
}
