// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    promptId: String
  }
  connect() {
    if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search)
        searchParams.set("prompt_id", searchParams.get("prompt_id") || this.promptIdValue);
        var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        history.pushState(null, '', newRelativePathQuery);
    }
  }
}
