import styled from 'styled-components';

export const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem;
  cursor: pointer;
`;

export const ResultImage = styled.img`
  height: 64px;
  width: 64px;
`;

export const SongContainer = styled.div`
  margin-left: 3rem;
`;

export const TitleText = styled.p`
  color: black;
`;

export const ArtistText = styled.p`
  color: black;
`;
