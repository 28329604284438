import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  remove() {
    let element = document.getElementById('prompt-wrapper');
    element.classList.remove('opacity-100');
    element.classList.add('opacity-0');
    setTimeout(() => { element.classList.add('h-0') }, 500);
    document.getElementById('writing_prompt_id').value = null;
    document.getElementById('remove-prompt').style.display = 'none';
  }

  newPrompt() {
    fetch("/writings/new_prompt").then(function(response) {
      return response.json()
    }).then(function(data) {
      document.getElementById('writing_prompt_id').value = data.id;
      document.getElementById('prompt-text').innerHTML = data.prompt;
      let element = document.getElementById('prompt-wrapper');
      element.classList.remove('opacity-0');
      element.classList.remove('h-0');
      element.classList.add('opacity-100');
      if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search)
        searchParams.set("prompt_id", data.id);
        var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        history.pushState(null, '', newRelativePathQuery);
      }
    })
  }

  disconnect() {
  }
}
