import React, { useState, useEffect }         from "react";
import Typewriter from 'typewriter-effect';



export default function TextRotation({words, postScript}) {

  const typewriter = new Typewriter('#typewriter', {
    strings: ['Hello', 'World'],
    autoStart: true,
  });

  useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div class="mt-0 mb-6 text-gray-700 leading-normal font-mini-display">
      <Typewriter
        options={{
          strings: words,
          autoStart: true,
          loop: true,
          cursor:"",
          wrapperClassName: "underline",
          skipAddStyles: true
        }}
      />{postScript}
    </div>
  )
}
