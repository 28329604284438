// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email"]

  connect() {
    if (window.sessionStorage.getItem("email")) {
      this.emailTarget.value = window.sessionStorage.getItem("email")
    }
  }

  click(e) {
    e.preventDefault()
    window.sessionStorage.setItem("email", this.emailTarget.value)
    window.location = "/users/sign_up"
  }
}
